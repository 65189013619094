*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#progress {
  position: absolute;
  width: calc(100% / 7);
  height: 4px;
  color: rgba(0, 0, 0, 1);
  border: 1px solid black;
  border-radius: 10px;
  top: calc(15%);
  left: calc(50% - 100% / 14);
  opacity: 0;
  transition: opacity 0.3s 0.3s;
}

#progress.show {
  transition-delay: 0s;
  opacity: 1;
}

#progress>.bar {
  width: 100%;
  height: 100%;
  background-color: currentColor;
  border-radius: 10px;
  transform-origin: top left;
  transform: scaleX(0);
}

.swiper-button-next, .swiper-button-prev {
  color:black !important;
  font-size: 10px !important;
}

/* .swiper-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
} */

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 14px !important;
  font-weight: 700;
}

@font-face {
  font-family: 'Crystal-ExpandedBold';
  font-style: normal;
  font-display: swap;
  src: url('/fonts/crystal/Crystal-ExpandedBold.woff2') format('woff2');
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
} 

@font-face {
  font-family: 'Crystal-Bold';
  font-style: normal;
  font-display: swap;
  src: url('/fonts/crystal/Crystal-Bold.woff2') format('woff2');
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
} 

@font-face {
  font-family: 'Crystal-CondensedBold';
  font-style: normal;
  font-display: swap;
  src: url('/fonts/crystal/Crystal-CondensedBold.woff2') format('woff2');
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
} 

@font-face {
  font-family: 'Crystal-CondensedRegular';
  font-style: normal;
  font-display: swap;
  src: url('/fonts/crystal/Crystal-CondensedRegular.woff2') format('woff2');
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
} 

@font-face {
  font-family: 'Crystal-Medium';
  font-style: normal;
  font-display: swap;
  src: url('/fonts/crystal/Crystal-Medium.woff2') format('woff2');
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
} 

@font-face {
  font-family: 'Crystal-Regular';
  font-style: normal;
  font-display: swap;
  src: url('/fonts/crystal/Crystal-Regular.woff2') format('woff2');
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
} 